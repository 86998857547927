#create-act-container{
    width: 40vw;
    min-width: 400px;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#category-select{
    height: 55px;
    border-radius: 4px;
    border: 1px solid rgb(200, 200, 200);
    padding: 10px;
    width: 100%;
}

#act-img{
    height: 200px;
    width: 200px;
    object-fit: cover;
    object-position: center;
    margin: 1rem;
}