#create-act-btn{
    width: 200px;
    height: 50px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 25px;
    border: unset;
    font-weight: bold;
    transition: 0.5s;
    margin: 1rem;
}

#create-act-btn:hover{
    cursor: pointer;
    background-color: rgb(200, 200, 200);
}

.act-img{
    height: 200px;
    width: 350px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
    margin: 1rem;
}

.act-container{
    background-color: rgb(240, 240, 240);
    display: flex;
    box-sizing: border-box;
    margin: 1rem 1rem;
    border-radius: 5px;
}

.act-roster{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
}

.venue-roster-img{
    width: 80%;
}

.act-roster-info{
    margin: 5px 0;
    font-size: 0.8rem;
    text-align: center;
}